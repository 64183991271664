import { FullServeProduct } from "@/types/product/categorizedProduct"
import { isArticle, isSalesProduct } from "@/types/product/listProduct"
import {
  CHECKOUT_ENABLED,
  CHECKOUT_LOCATIONS,
  FULLSERVE_DISABLED_MARKETS,
  FULLSERVE_DISABLED_STORES,
  ISOM_ORDERS_ENABLED,
  isSkipProbabilityCheckTestEnabled,
  TSM_ENABLED,
  TSM_UPDATE_ENABLED,
} from "../config/constants"

export type FullServeRestriction =
  | "DISABLED"
  | "DISABLED_RANGE"
  | "LOW_STOCK"
  | "NO_TIME_SLOT"

export type FullServeError =
  | "API_ERROR"
  | "UNKNOWN_STORE"
  | FullServeRestriction

export function getFullServeRestriction(
  products: FullServeProduct[],
  market: string,
  storeNo: string,
  noTimeSlots: boolean,
): FullServeRestriction | undefined {
  if (isFullServeDisabled(market, storeNo)) {
    return "DISABLED"
  }
  if (isFullServeDisabledRange(products, market, storeNo)) {
    return "DISABLED_RANGE"
  }
  const hasEnoughStock = isSkipProbabilityCheckTestEnabled(market, storeNo)
    ? fullServeHasEnoughStock(products)
    : fullServeHasEnoughStockWithProbability(products)
  if (!hasEnoughStock) {
    return "LOW_STOCK"
  }
  if (noTimeSlots) {
    return "NO_TIME_SLOT"
  }
}

function isFullServeDisabled(market: string, storeNo: string): boolean {
  return (
    FULLSERVE_DISABLED_MARKETS.includes(market.toUpperCase()) ||
    FULLSERVE_DISABLED_STORES.includes(storeNo)
  )
}

function isFullServeDisabledRange(
  products: FullServeProduct[],
  market: string,
  storeNo: string,
): boolean {
  return !!products.find((product) =>
    isSalesProduct(product) && product.info.childItems
      ? isFullServeDisabledRange(product.info.childItems, market, storeNo)
      : isArticle(product) && product.disabledRange?.isFullServeDisabled,
  )
}

/** FullServeProduct with only quantity and stock.cashCarry, for ease of testing */
type FSProductWithQuantityAndCashCarry = Pick<FullServeProduct, "quantity"> & {
  stock: Pick<FullServeProduct["stock"], "cashCarry">
}

export function fullServeHasEnoughStock(
  products: FSProductWithQuantityAndCashCarry[],
): boolean {
  return products.every((product) => {
    if (product.stock.cashCarry.inRange && product.stock.cashCarry.quantity) {
      return product.stock.cashCarry.quantity >= product.quantity
    }
  })
}

export function fullServeHasEnoughStockWithProbability(
  products: FSProductWithQuantityAndCashCarry[],
): boolean {
  return products.every((product) => {
    if (
      product.stock.cashCarry.inRange &&
      product.stock.cashCarry.quantity &&
      product.stock.cashCarry.probability
    ) {
      switch (product.stock.cashCarry.probability.thisDay) {
        case "HIGH_IN_STOCK": {
          return product.stock.cashCarry.quantity >= product.quantity
        }
        case "MEDIUM_IN_STOCK": {
          // for medium in stock we need one as buffert
          return product.stock.cashCarry.quantity > product.quantity
        }
        default: {
          return false
        }
      }
    } else {
      return false
    }
  })
}

/**
 * Determine which version of fullserve endpoints to use.
 * @returns True if version 2 is used, false if version 1 is used, undefined if configuration is missing.
 */
export function isIsomFullServe(market: string, buCode: string, ukid: string) {
  return (
    TSM_UPDATE_ENABLED ||
    TSM_ENABLED ||
    CHECKOUT_ENABLED ||
    ISOM_ORDERS_ENABLED.markets.includes(market.toUpperCase()) ||
    ISOM_ORDERS_ENABLED.stores.includes(buCode.toUpperCase()) ||
    ISOM_ORDERS_ENABLED.kiosks.includes(ukid.toUpperCase())
  )
}

/**
 * Determine if we should create isom fullserve orders via Checkout api.
 */
export function isIsomFullServeCheckout(
  market: string,
  buCode: string,
  ukid: string,
) {
  return (
    TSM_UPDATE_ENABLED ||
    TSM_ENABLED ||
    CHECKOUT_ENABLED ||
    CHECKOUT_LOCATIONS.markets.includes(market.toUpperCase()) ||
    CHECKOUT_LOCATIONS.stores.includes(buCode.toUpperCase()) ||
    CHECKOUT_LOCATIONS.kiosks.includes(ukid.toUpperCase())
  )
}
